



























































































































































































































































































.tree-node {
  padding: 0 14px 0 32px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  height: 37px;
  color: #4d4d4d;
  position: relative;
  transition: background-color 0.1s;

  .link {
    color: #4d4d4d;
  }

  &:hover .tree-node__context-menu {
    opacity: 1;
  }

  &:hover,
  &:focus,
  &_active {
    background-color: #efefef;
  }

  &__wrapper {
    position: relative;
  }

  &_active {
    box-shadow: inset -5px 0px 0px #666666;
  }

  &__icon-wrapper {
    height: 100%;
    padding: 8px 0;
    box-sizing: border-box;
  }

  &__icon {
    display: block;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    transform: rotateZ(-90deg);

    &-open {
      transform: rotateZ(0);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__drop-zone {
    display: inline-block;
    margin: 0 8px;
    width: 100%;
  }

  &__name,
  &__link {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
    color: #808080;
  }

  &__context-menu {
    display: inline-flex;
    align-items: center;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &__context-menu-button {
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    .c-icon-three-dots {
      line-height: 18px;
      color: #999999;
    }
  }
}

.tree-drop-line {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 5px;
  // background-color: rgba(255, 0, 0, 0.548);

  &__top {
    top: 0px;
    bottom: initial;
  }
}
