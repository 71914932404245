
























































































































































































































































































.tree {
  min-width: 440px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f5f5f5;

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 100%;
    color: gray;
  }
}
