<template>
  <div
    class="drag-container"
    :draggable="draggable"
    @dragstart="dragStart"
    @mousedown="mouseDown"
    @dragend="dragEnd"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { HIGHLIGHT, HIDE_HIGHLIGHT } from "./drag-&-drop-event-bus/events";
import { store, Keys } from "./drag-&-drop-data-transfer";
import EventBus from "./drag-&-drop-event-bus";

@Component
export default class Drag extends Vue {
  @Prop({ default: null }) data: any;
  @Prop({ default: true, type: Boolean }) enable!: boolean;
  @Prop() dragElement!: string;
  @Prop({ default: "", type: [String, Array] }) dropName!:
    | Array<string>
    | string;

  private get draggable(): boolean {
    return this.enable;
  }

  // leave element and remove all listeners
  private dragEnd(): void {
    EventBus.$emit(HIDE_HIGHLIGHT, this.dropName);
    document.removeEventListener("mouseup", this.dragEnd);
  }

  // click
  mouseDown(event: MouseEvent): void {
    // only for left mouse click
    if (event.button !== 0) return;
    // if not enabled prevent all actions
    if (!this.enable) return;
    // if target not drag element
    if (
      this.dragElement &&
      !(event?.target as HTMLElement).closest(this.dragElement)
    ) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    document.addEventListener("mouseup", this.dragEnd);
    store.set(Keys.dragData, this.data);
    store.set(Keys.dropName, this.dropName);
  }

  dragStart(): void {
    if (!this.enable) return;
    this.$emit("dragstart", store.getState());
    EventBus.$emit(HIGHLIGHT, this.dropName);
  }

  beforeDestroy(): void {
    document.removeEventListener("mouseup", this.dragEnd);
  }
}
</script>

<style scoped>
.drag-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: transform;
  box-sizing: border-box;
}

.enable {
  cursor: grab;
}
</style>
