




























































































































.droppable {
  position: relative;
  // border: 1px solid transparent;

  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.212);
  }

  &__drop-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    // background-color: rgba(0, 255, 242, 0.01);
  }
}
